import { FeedbackAttachment } from "~/types/Attachment";

export const useReportProblem = () => {
  const dataURItoBlob = (dataURI: string) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  };

  const reportProblem = async (
    feedback: ReportedFeedback,
    attachments: FeedbackAttachment[]
  ) => {
    const formData = new FormData();

    // Append problem details
    formData.append("feedback", JSON.stringify(feedback));

    // Append attachments
    attachments.forEach((attachment, index) => {
      if (attachment.type === "image") {
        // Convert base64 to Blob if the attachment is an image
        const blob = dataURItoBlob(attachment.data as string);
        formData.append(
          `attachments[${index}]`,
          blob,
          attachment.name || `image-${index}.png`
        );
      } else {
        // Directly append if the attachment is a File
        formData.append(
          `attachments[${index}]`,
          attachment.data as File,
          attachment.name
        );
      }
    });

    // Send the FormData via fetch
    const headers = await useApiHeaders(); // Get headers, but don't set Content-Type
    delete headers["Content-Type"]; // Let the browser set it

    await fetch("/api/support/problem", {
      method: "POST",
      headers: headers,
      body: formData,
    });
  };

  return { reportProblem };
};
